// 

import { instance } from '@/utils/http.js'

// 根据科目，地区获取来源
export const getSourceList = function (params) {
  return instance({
    url: '/api/v1/school/get_source',
    method: 'get',
    params
  })
}