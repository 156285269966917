<template>
  <div>
    <el-dialog title="导入光大数据"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               v-if="dialogVisible"
               :rules="rules"
               label-width="160px">
        <!-- <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="用户账号类型"
                          prop="user_type_id">
              <el-select v-model="form.user_type_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in userType"
                           :key="item.user_type_id"
                           :label="item.user_type_name"
                           :value="item.user_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教师试卷id"
                          prop="edu_paper_id">
              <el-input v-model="form.edu_paper_id"
                        @change="inputChange"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="指定年级"
                          prop="given_garde">
              <el-select v-model="form.given_garde"
                         clearable
                         style="width:100%"
                         @change="gradeChange"
                         placeholder="选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="试卷科目"
                          prop="subject_id">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         :disabled="isResource"
                         @change="subjectChage"
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="试卷地区"
                          prop="district_id">
              <el-select v-model="form.district_id"
                         style="width:100%"
                         filterable
                         @change="changeDistrict"
                         :disabled="isResource"
                         placeholder="选择">
                <el-option v-for="item in districtList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="试卷年份"
                          prop="question_year">
              <el-select v-model="form.question_year"
                         style="width:100%"
                         @change="changeYear"
                         :disabled="isResource"
                         placeholder="选择">
                <el-option v-for="item in year"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="详细来源">
              <el-switch v-model="form.source_paper"
                         :active-value="1"
                         :inactive-value="0"
                         active-text="是"
                         inactive-text="否"
                         @change="changeSourceType" />
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="10">

          <el-col :span="24"
                  v-show="form.source_paper==0">
            <el-form-item label="试卷来源"
                          prop="source_id">
              <el-select v-model="form.source_id"
                         :disabled="fromResource"
                         filterable
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">

          <el-col :span="24"
                  v-show="this.form.source_paper==1">
            <el-form-item label="试卷来源"
                          prop="source_id">
              <el-select v-model="form.source_id"
                         :disabled="fromResource"
                         filterable
                         clearable
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in sourceList1"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                  <span style="float: left">{{ item.source_name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px"
                        v-if="item.has_paper_source">在教师试卷{{ item.has_paper_source }}使用过</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="学伴截止时间"
                          prop="end_time">
              <el-date-picker v-model="form.end_time"
                              type="datetime"
                              style="width:100%"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              default-time="23:59:59"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="导入文件"
                          prop="file">
              <el-button type="primary"
                         style="padding:10px 30px"
                         @click="checkFile"
                         v-if="!file.name">选择</el-button>
              <el-tag @close="clear"
                      v-else
                      closable
                      type="priamry">
                {{file.name?file.name:''}}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSourceList as getSourceListApi } from '@/api/source.js'
import knowledgeFormVue from '../../../../../sl_admin/src/views/teachingResource/chapter/components/knowledgeForm.vue'
export default {
  data () {
    return {
      options: [],
      file: {},
      form: {},
      dialogVisible: false,
      sourceList: [],
      year: [],
      // 选择校本资源为来源的时候
      isResource: false,
      // 从校本资源页面点击组卷的时候
      fromResource: false,
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        source_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        semester: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        // file: [
        //   { required: true, message: '请选择', trigger: 'change' },
        // ],

      },
      districtList: [],
      userType: [],
      gradeList: [], isResource: false,
      isResource: false,
      sourceList1: [],
      subjectList: []

    }
  },
  created () {
    this.getList()
  },
  methods: {
    getSubjectList () {
      if (!this.form.given_garde && !this.form.given_class) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
          // class_id: this.form.given_class
        }
      }).then(res => {
        this.subjectList = res.data
        // let arrList = Object.values(this.subjectList)
        // if (this.form.subject_id && arrList.filter(item => item.subject_id == this.form.subject_id).length == 0) {
        //   this.form.subject_id = arrList[0].subject_id
        // } else if (!this.form.subject_id) {
        //   this.form.subject_id = arrList[0].subject_id
        // }
        // this.subjectChage()
        // console.log(this.subjectList)
      })
    },
    //是否显示详细来源
    changeSourceType () {
      if (this.form.source_paper == 1) {
        if (this.isResource) {
          // this.getClass()
          this.getGradeList()
          this.isResource = false
        }

        this.getSourceList1()

        // this.reset()
      }
      if (this.form.source_paper == 0 && this.sourceList.length == 0) {
        this.getSourceList()
      }
      this.form.source_id = ''
    },

    getDetail () {
      this.$http({
        url: '/api/v1/file/source_detail',
        method: 'get',
        params: {
          source_id: this.form.source_id
        }
      }).then(res => {
        let data = res.data
        this.setSubjectAndYearAndDis(data.subject_id, data.year, data.paper_district_id)
        this.clearGradeAndClass()
        this.subjectList = [
          { subject_id: data.subject_id, subject_name: data.subject_name }
        ]
        this.isResource = true
        this.getGradeAndClass()
      })
    },
    changeYear () {
      if (this.form.source_paper == 1) {
        this.getSourceList1()
      }
    },
    async getSourceList1 () {
      // this.form.source_id = ''
      this.sourceList1 = []

      if (!this.form.subject_id || !this.form.district_id) return

      let params = {
        subject_id: this.form.subject_id,
        district_id: this.form.district_id,
        year: this.form.question_year,
        grade_id: this.form.given_garde,
        show_history: 1
      }
      const { data } = await getSourceListApi(params)
      this.sourceList1 = data.list
      if (this.form.source_id && this.sourceList1 && this.sourceList1.length > 0) {
        if (this.sourceList1.filter(item => item.source_id == this.form.source_id).length == 0) {
          this.form.source_id = this.sourceList1[0].source_id
        }
      } else if (this.form.source_id && this.sourceList1 && this.sourceList1.length == 0) {
        this.form.source_id = ''
      } else if (!this.form.source_id) {
        // this.form.source_id = this.sourceList1[0].source_id
        this.form.source_id = ''

      }
    },

    getList () {
      this.getGradeList()
      this.getSourceList()
      this.getYearList()
      // this.getClass()
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
        if (this.form.given_garde) {
          if (this.gradeList.filter(item => item.grade_id == this.form.given_garde).length == 0) {
            this.form.given_garde = this.gradeList[0].grade_id
          }
          this.gradeChange()
        }
      })
    },
    getYearList () {

      this.$http({
        url: '/api/v1/public/year_all',
        method: 'get',
      }).then(res => {
        this.year = res.data
      })
    },
    changeDistrict () {
      // 详细来源与地区相关
      if (this.form.source_paper == 1) {

        this.getSourceList1()
      }
    },
    subjectChage () {
      this.paperTypeList = []
      this.form.edu_paper_type = ''

      if (this.form.given_garde == '' && this.form.given_class == '' && this.form.subject_id == '') return
      if (this.form.given_garde) {
        var form = {
          grade_id: this.form.given_garde,
          subject_id: this.form.subject_id
        }
      } else {
        var form = {
          special_class_subject_id: this.form.subject_id
        }
      }
      this.getDirList()
      // this.getPaperTypeList(form)
    },
    inputChange (val) {
      console.log('val', val)
      this.rules = {}
    },
    gradeChange (value) {
      this.subjectList = []
      this.getSubjectList()
    },
    getSourceList () {
      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
        // if (this.sourceList && this.form.source_id && (this.sourceList.filter(item => item.source_id == this.form.source_id)).length == 0) {
        //   this.form.source_id = 1
        // }
        // this.getOther()
      })
    },
    checkRadio () {
      if (this.form.isCover == 0) {
        this.form.isCover = 1
      } else {
        this.form.isCover = 0
      }
    },
    clear () {
      this.file = {}
    },
    handleClose () {
      this.file = {}
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      if (this.file == {}) {
        this.$message.error('请选择文件')
        return
      }
      this.$refs.formRef.validate(val => {
        if (!val) return
        let formData = new FormData()
        console.log(this.form)
        let copyFporm = { year: this.form.question_year, source_id: this.form.source_id, edu_paper_id: this.form.edu_paper_id }

        for (const key in copyFporm) {
          formData.append(key, copyFporm[key])
        }
        formData.append('file', this.file)
        formData.append('sign', this.$getMd5Form(copyFporm))
        this.$http({
          url: '/api/v1/tang/import_guangda_user',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },
    checkFile () {
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        // console.log(res);
        this.file = res
      })
    },

    getDirList () {
      this.$http({
        // url: '/api/v1/public/select_district',
        url: '/api/v1/combination/region',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          grade_id: this.form.grade_id
        }
      }).then(res => {

        this.districtList = res.data.districtList
        if (this.form.source_paper == 1) {
          this.getSourceList1()
        }
      })
    },
    // getList () {
    //   //用户类型
    //   this.$http({
    //     url: '/api/v2/public/user_type',
    //     method: 'get'
    //   }).then(res => {
    //     this.userType = res.data.list
    //   })

    //   //试卷地区
    //   this.$http({
    //     url: '/api/v2/public/paper_district',
    //     method: 'get'
    //   }).then(res => {
    //     this.districtList = res.data.list
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  display: none;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>